import header from "./header";
import login from "./login";
import dialog from "./dialog";
import triggers from "./triggers"

const module = {
  header,
  login,
  dialog,
  triggers
};
export default module;
