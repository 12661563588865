
// import { getUserInfo } from "@api/user";
// import dialog from "@utils/dialog";
import Session from "@/utils/session.js";

const state = {
    isLogin: false,
    userInfo: {},
    contact: "",
    denyReason: "",
    contactTrigger: false,
    claimContactTrigger:false,
    denyTrigger: false,
    isRefreshUserInfo: ""
}
const getters = {
    isLogin: state => state.isLogin,
    userInfo: state => state.userInfo,
    contact: state => state.contact,
    denyReason: state => state.denyReason,
    contactTrigger: state => state.contactTrigger,
    claimContactTrigger: state => state.claimContactTrigger,
    denyTrigger: state => state.denyTrigger,
}
const mutations = {
    setIsLogin(state, data) {
        state.isLogin = data
    },
    setUserInfo(state, data) {
        let ses = new Session();
        if (data) {
            ses.setSession("isLogin", true, "local");
            let bar = JSON.stringify(data);
            ses.setSession("bar", bar, "local");
            state.userInfo = data;
            state.contact = data.contact;
        }
        else {
            ses.sessionClear("isLogin", "local");
            ses.sessionClear("bar", "local");
            state.userInfo = {};
            state.contact = "";
        }
    },
    setContact(state, [data, collectType]) {
        state.contact = data;
        if (collectType == 'claimContact') {
            state.claimContactTrigger = new Date().getTime().toString();
        }
        else {
            state.contactTrigger = new Date().getTime().toString();
        }
    },
    setDenyReason(state, data) {
        state.denyReason = data;
        state.denyTrigger = new Date().getTime().toString();
    },
    setRefreshUserInfo(state, data) {
        state.isRefreshUserInfo = data;
    },
}

const actions = {

}

export default {
    getters,
    state,
    mutations,
    actions
}