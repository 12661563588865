export const lang = {
    home: '主页',
    about: '关于我们',
    loginin: '登录',
    logininTip: ['已跳转至UNIPASS', '请点击', '这里', '如果浏览器没有自动跳转'],
    SignIn: '登录|注册',
    createNewInvite: '创建新的邀请',
    editEvent: "修改您的邀请",
    reviewEvent: "查看邀请详情",
    formProp: ['您想邀请？', '酬金', '标题', '备注', '开始 日期/时间 - 结束 日期/时间', '位置', '联系方式', '+ 添加其他联系方式', '提交', '暂时不想创建邀请了'],
    formHolder: ['请输入关键词', '搜索', '您打算支持多少$COOP作为报酬？', '您打算邀请对方的主题是？', '多告诉大家一点信息, 最长300字', '开始时间', '结束时间', '输入一个地址或者一个链接 (https://www...)', '您想让别人通过何种途径找到你？请至少填写一种方式，默认使用您已经保存的地址', '(不填，默认视为开放申请)', '查找你想邀请的人'],
    invites: '邀请',
    invites_pub: "发起人：",
    share: '分享',
    shareStatus: ['您已成功复制链接，快去分享给你的小伙伴吧！', '复制出现问题，请刷新后重试！'],
    notInterested: '不感兴趣',
    accept: '接受',
    cancel: '取消',
    edit: '编辑',
    review: '查看',
    delete: '删除',
    completed: '完成',
    received: '已收到酬金',
    usercenter: '个人中心',
    logout: '退出',
    comments: '评论',
    claims: '申请人',
    messageDetail: '详情',
    cancelclaim: '取消申领',
    claim: '申领任务',
    none: '无',
    submit: '提交',
    addComment: '添加一条评论',
    viewmore: '查看更多',
    commentNull: "评论不能为空",
    hoursBefore: "小时前",
    minutesBefore: "分钟前",
    secondsBefore: "秒前",
    loading: "努力加载中~",
    chooseClaims: "请从下方申请人中选择你想邀请的那一个",
    inviteMethodOne: '直接邀请',
    inviteMethodTwo: '从申请人中邀请',
    lang: "中|EN 切换",
    newest: "最近",
    oldest: "最早",
    upload: "点击上传",
    uploadLimit: "只能上传jpg/png文件，且不超过500kb",
    chooseAvatar: "请选择头像",
    nickName: "昵称",
    description: "描述",
    events: "相关邀请",
    messages: "消息",
    contact: "联系方式",
    type: "类型",
    confirmContact: "确认你的联系方式",
    denyReason: "填写不感兴趣的原因",
    validError: "验证失败，请检查表单",
    continueWithoutReason: "不想填写原因，继续",
    receiverContact: "被邀请人联系方式",
    postUserContact: "发起人联系方式",
    initProfileContact: "请填写您的默认联系方式",
    denyReasonTip: "请告诉对方您不感兴趣的原因（非必填）",
    addOtherContact: "+ 添加其他联系方式",
    submitSuccess: "提交成功！",
    learmore: "了解更多",
    statusOptions: [
        "全部",
        "可申请",
        "进行中",
        "已完结"
    ]
}