import Vue from 'vue'
import Vuex from 'vuex'
import module from './modules'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: module
})

export default store