<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const search = window.location.search;
    if (this._isMobile()) {
      if (!this.$router.history.pending.fullPath.includes("unipass_ret")) {
        if (
          this.$router.history._startLocation.indexOf("user_profile_wap") > 0 ||
          this.$router.history._startLocation.indexOf("user_profile_pc") > 0
        ) {
          this.$router.replace(`/user_profile_wap${search}`);
        } else if (
          this.$router.history.pending.fullPath.includes("about_pc") ||
          this.$router.history.pending.fullPath.includes("about_wap")
        ) {
          this.$router.replace(`/about_wap`);
        } else if (
          this.$router.history.pending.fullPath.includes("user_pc") ||
          this.$router.history.pending.fullPath.includes("user_wap")
        ) {
          this.$router.replace(`/user_wap${search}`);
        } else {
          this.$router.replace(`/coopList_wap${search}`);
        }
      }
    } else {
      if (!this.$router.history.pending.fullPath.includes("unipass_ret")) {
        if (
          this.$router.history._startLocation.indexOf("user_profile_pc") > 0 ||
          this.$router.history._startLocation.indexOf("user_profile_wap") > 0
        ) {
          this.$router.replace(`/user_profile_pc${search}`);
        } else if (
          this.$router.history.pending.fullPath.includes("about_pc") ||
          this.$router.history.pending.fullPath.includes("about_wap")
        ) {
          this.$router.replace(`/about_pc`);
        } else if (
          this.$router.history.pending.fullPath.includes("user_pc") ||
          this.$router.history.pending.fullPath.includes("user_wap")
        ) {
          this.$router.replace(`/user_pc${search}`);
        } else {
          this.$router.replace(`/coopList_pc${search}`);
        }
      }
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
html,
body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  overflow: hidden;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 62.5%;
}
#app {
  height: 100%;
  overflow: auto;
}
</style>
