import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import router from "./router";
import store from "./store";
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./style/index.scss";
import Meta from "vue-meta";
import VueClipboard from 'vue-clipboard2'
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false

Vue.use(VueClipboard)
Vue.use(Meta)
Vue.use(Vuex)
Vue.use(VueRouter)
// Vue.use(ElementUI)
Vue.use(VueI18n) 

const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./data/lang/zh'),   // 中文语言包
    'en-US': require('./data/lang/en')    // 英文语言包
  }
})
new Vue({
  render: h => h(App),
  i18n,
  store,
  router
}).$mount('#app')
