const state = {
  language: "zh",
  keywords: "",
  menubar: {
    status: document.body.offsetWidth < 768 ? 'PHN' : 'PCE',
    menuList: [],
    isPhone: document.body.offsetWidth < 768
  },
  isCollapse: false
};
const getters = {
  language: (state) => state.language,
  keywords: (state) => state.keywords,
  menubar: (state) => state.menubar,
  isCollapse: (state) => state.isCollapse,
};
const mutations = {
  setLanguage(state, data) {
    state.language = data;
  },
  setKeywords(state, data) {
    state.keywords = data;
  },
  setMenuBar(state, data) {
    state.menubar = data
  },
  setIsCollapse(state) {
    state.isCollapse = !state.isCollapse
  }
};

const actions = {};

export default {
  // namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
