import VueRouter from "vue-router";
import store from "@/store/index.js"

const routes = [
  {
    path: "/",
    meta: {
      title: "init",
      activeMenu: 0,
    },
    redirect: {
      name: "coopList_pc",
    },
  },
  {
    path: "/home_pc",
    name: "home_pc",
    meta: {
      isPc: true,
      title: "home_pc",
      activeMenu: 1,
    },
    component: () => import("@views/home/pc/home_pc.vue"),
    children: [{
      path: "/coopList_pc",
      name: "coopList_pc",
      meta: {
        isPc: true,
        title: "coopList_pc",
        activeMenu: 1_1,
      },
      component: () => import("@views/coopList/coopList_pc.vue"),
    }, {
      path: "/user_pc",
      name: "user_pc",
      meta: {
        isPc: true,
        title: "user_pc",
        activeMenu: 1_2,
      },
      component: () => import("@views/user/user_pc.vue"),
    }, {
      path: "/about_pc",
      name: "about_pc",
      meta: {
        isPc: true,
        title: "about_pc",
        activeMenu: 1_3,
      },
      component: () => import("@views/about/about_pc.vue"),
    }, {
      path: "/user_profile_pc",
      name: "user_profile_pc",
      meta: {
        isPc: true,
        title: "user_profile_pc",
        activeMenu: 1_4,
      },
      component: () => import("@views/user/user_profile_pc.vue"),
    }]
  },
  {
    path: "/home_wap",
    name: "home_wap",
    meta: {
      isPc: true,
      title: "home_wap",
      activeMenu: 2,
    },
    component: () => import("@views/home/wap/home_wap.vue"),
    children: [{
      path: "/coopList_wap",
      name: "coopList_wap",
      meta: {
        isPc: false,
        title: "coopList_wap",
        activeMenu: 2_4,
      },
      component: () => import("@views/coopList/coopList_wap.vue"),
    }, {
      path: "/user_wap",
      name: "user_wap",
      meta: {
        isPc: false,
        title: "user_wap",
        activeMenu: 2_5,
      },
      component: () => import("@views/user/user_wap.vue"),
    }, {
      path: "/about_wap",
      name: "about_wap",
      meta: {
        isPc: false,
        title: "about_wap",
        activeMenu: 2_6,
      },
      component: () => import("@views/about/about_wap.vue"),
    }, {
      path: "/user_profile_wap",
      name: "user_profile_wap",
      meta: {
        isPc: false,
        title: "user_profile_wap",
        activeMenu: 2_4,
      },
      component: () => import("@views/user/user_profile_wap.vue"),
    }]
  },
  {
    path: "/404",
    name: "404",
    meta: {
      isPc: true,
      title: "404",
      activeMenu: 6,
    },
    component: () => import("@views/notFound/notFound.vue"),
  },
  {
    path: "/:pathMatch(.*)", // 此处需特别注意置于最底部
    meta: {
      title: "404",
      activeMenu: 7,
    },
    redirect: "/404",
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.activeMenu) {
    // 个人中心刷新个人信息以及缓存
    if (to.name == "user_pc" || to.name == "user_wap") {
      store.commit("setRefreshUserInfo", (new Date().getTime().toString()));
    }
    next();
  } else {
    next({
      path: "/404",
    });
  }
});

export default router;
