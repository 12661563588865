const state = {
    isShowNewEventDialog: false,  //新建
    isShowEditEventDialog: false,  //编辑
    isShowReadOnlyEventDialog: false,  //只读
    isShowInfoCollectDialog: false,  //收集联系方式或者拒绝理由
    isShowModuleDialog: false,
    moduleEventId: null,
    collectType: "",
    editEventId: null,
    readOnlyEventId: null,
}
const getters = {
    isShowNewEventDialog: state => state.isShowNewEventDialog,
    isShowEditEventDialog: state => state.isShowEditEventDialog,
    isShowReadOnlyEventDialog: state => state.isShowReadOnlyEventDialog,
    isShowInfoCollectDialog: state => state.isShowInfoCollectDialog,
    isShowModuleDialog: state => state.isShowModuleDialog,
    moduleEventId: state => state.moduleEventId,

    collectType: state => state.collectType,
    editEventId: state => state.editEventId,
    readOnlyEventId: state => state.readOnlyEventId,
}
const mutations = {
    setNewEventDialog(state, data) {
        state.isShowNewEventDialog = data
    },
    setEditEventDialog(state, [isShowEditEventDialog, editEventId]) {
        state.editEventId = editEventId;
        state.isShowEditEventDialog = isShowEditEventDialog;
    },
    setReadOnlyEventDialog(state, [isShowReadOnlyEventDialog, readOnlyEventId]) {
        state.readOnlyEventId = readOnlyEventId;
        state.isShowReadOnlyEventDialog = isShowReadOnlyEventDialog;
    },
    setInfoCollectDialog(state, [visible, type]) {
        state.isShowInfoCollectDialog = visible;
        state.collectType = type
    },
    setCollectType(state, data) {
        state.collectType = data
    },
    setModuleDialog(state, [isShowModuleDialog, moduleEventId]) {
        state.isShowModuleDialog = isShowModuleDialog;
        state.moduleEventId = moduleEventId
    },
}

const actions = {

}

export default {
    getters,
    state,
    mutations,
    actions
}