export const lang = {
    home: 'Home',
    about: 'About',
    loginin: 'Login In',
    logininTip: ['You are being redirected to Unipass,', 'please click', 'here', 'if your browser does not redirect you.'],
    SignIn: 'Sign In',
    createNewInvite: 'create a new event',
    editEvent: 'Edit your event',
    reviewEvent: 'Read event detail',
    formProp: ['INVITE', 'BOUNTY', 'EVENT TOPIC', 'DESCRIPTION(optional)', 'START DATE/TIME - END DATE/TIME', 'LOCATION (optional)', 'CONTACT DETAILS', '+ add another contact method', 'Submit', 'I don’t want to create an event anymore'],
    formHolder: ['Search for the person', 'Search', 'How much $COOP do you want to pay for this person?', 'What’s this activity?', 'Tell people a little more about this activity? No more than 200 words', 'START DATE', 'END DATE', 'Enter a location where the event will be or a link (https://www...)', 'How do you want others to find you? Please fill up at least one option, it will be filled by default if you have saved before in your profile', '(if empty, means everyone can claim for it)', 'Search the one you want to invite'],
    invites: 'invites',
    invites_pub: "Creator:",
    share: 'share',
    shareStatus: ['success copied', 'copy failed, refresh and try again'],
    notInterested: 'Not interested',
    accept: 'Accept',
    cancel: 'Cancel',
    edit: 'Edit',
    review: 'Review',
    delete: 'Delete',
    completed: 'Completed',
    received: 'Coop received',
    usercenter: 'Profile',
    logout: 'Log out',
    comments: 'Comments',
    claims: 'Claims',
    messageDetail: 'Event Detail',
    cancelclaim: 'Cancel Claim',
    claim: 'Claim',
    none: 'None',
    submit: 'Submit',
    addComment: 'Add a comment',
    viewmore: 'View more',
    commentNull: "submit cann't be null",
    hoursBefore: "hours ago",
    minutesBefore: "minutes ago",
    secondsBefore: "seconds ago",
    loading: "loading...",
    chooseClaims: "invite from applicants",
    inviteMethodOne: 'invite directly',
    inviteMethodTwo: 'invite from applicants',
    lang: "中|EN 切换",
    newest: "Newest",
    oldest: "Oldest",
    upload: 'Upload',
    uploadLimit: 'only jpg/png supported, no more than 500KB',
    chooseAvatar: "please choose an avatar",
    nickName: "NickName",
    description: "Description",
    events: "Events",
    messages: "Messages",
    contact: "Contact",
    type: "type",
    confirmContact: "confirm your contact",
    denyReason: "why do you deny?",
    validError: "valid error, please check your form",
    continueWithoutReason: "Continue without reason",
    receiverContact: "receiver contact detail",
    postUserContact: "postUser contact detail",
    initProfileContact: "please init your contact detail",
    denyReasonTip: "why you deny the invitation (optional)",
    addOtherContact: "+ another contact detail",
    submitSuccess: "submit successfully!",
    learmore: "learn more",
    statusOptions: [
        "All",
        "Available",
        "In progress",
        "Done"
    ]
}