// 用于跨组件调用

const state = {
    updataListByManul: false,  //创建后首页刷新
    updataUserEventByEdit: false, //编辑后个人页面刷新
    scenesloading: false
}
const getters = {
    updataListByManul: state => state.updataListByManul,
    updataUserEventByEdit: state => state.updataUserEventByEdit,
    scenesloading: state => state.scenesloading,
}
const mutations = {
    setUpdataListByManul(state, data) {
        state.updataListByManul = data
    },
    setUpdataUserEventByEdit(state, data) {
        state.updataUserEventByEdit = data
    },
    setScenesloading(state, data) {
        state.scenesloading = data
    },
}

const actions = {

}

export default {
    getters,
    state,
    mutations,
    actions
}